
import { defineComponent, ref, reactive } from "vue";
import CloudFun from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "會員等級",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "Name",
          title: "等級名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "UpgradeAmount",
          title: "升級金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          align: "right",
          formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue)
        },
        {
          field: "PreviewDiscount",
          title: "折扣",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      promises: {
        query: model
          ? (params) => new Promise(resolve => {
            model.dispatch('memberGrade/query', params).then(res => {
              res.data.forEach((e: any) => {
                e.DisplayDiscount = 100 - e.Discount * 100;
                e.PreviewDiscount = e.Discount === 0 ? "無" : e.DisplayDiscount === 0 ? "免費" : e.DisplayDiscount.toString().replaceAll("0", "") + "折"
              })
              resolve(res);
            })
          })
          : undefined,
        queryAll: model ? () => model.dispatch('memberGrade/query') : undefined,
        save: model
          ? (params) => model.dispatch('memberGrade/save', params)
          : undefined
      },
      modalConfig: { width: "60%", height: "auto", showFooter: true },
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "UpgradeAmount", title: "升級金額", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入升級金額" } } },
        { field: "DisplayDiscount", title: "折扣", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入折數, 例如: 9折則輸入90", min: 0, max: 100 } } },
        {
          field: "Description",
          title: "說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              placeholder: "請輸入說明"
            },
          },
        }
      ],
      rules: {
        Name: [{ required: true }],
        UpgradeAmount: [{ required: true }]
      },
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  },
  methods: {
    onFormSubmit(row: any, callback: any) {
      row.Discount = (100 - row.DisplayDiscount) / 100;
      callback();
    }
  }
});
